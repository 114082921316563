<script setup>
const props = defineProps({
  data: Array,
  fixed: Boolean,
});

const siteStore = useSiteStore();
const reducer = (acc, cv) => {
  acc = acc + cv.length;
  return acc;
};
const size = props.data
  ? props.data.reduce(reducer, 0)
  : siteStore.settings.marquee.reduce(reducer, 0);
</script>

<template>
  <div class="outer" :style="{ '--size': `${size}s` }" :class="{ fixed }">
    <div class="inner">
      <p v-for="i in 4" class="text-sm upper">
        <span v-for="item in data || siteStore.settings.marquee">{{
          item
        }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.outer {
  --color: black;
  width: 100%;
  // background: white;
  color: var(--color);
  border-top: 1px solid var(--color);
  border-bottom: 1px solid var(--color);
  padding: var(--xxxs) 0;
  overflow: hidden;
  position: relative;

  &.fixed {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.inner {
  display: flex;
  flex-wrap: nowrap;
  height: 1.5em;
  align-items: center;
}

p {
  white-space: nowrap;
  will-change: transform;
  transform: translateX(-50%);
  animation: marquee 10s infinite linear;
  animation-duration: calc(var(--size) / 5);
}

span {
  margin: 0 var(--xs);
}

@keyframes marquee {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(-150%);
  }
}
</style>
